<template>
  <div class="main">
    <van-nav-bar fixed title="核销结果" left-arrow @click-left="onClickLeft" />
    <div class="resultBox">
      <div class="smile">
        <img src="@/assets/images/fail.png" alt="" />
      </div>
      <div class="fail">核销失败</div>
      <p class="describ">失败原因：{{ msg }}</p>
      <div class="btnBox">
        <van-button
          :color="globalStyle.themeColor"
          class="leftBtn"
          plain
          type="primary"
          @click="handleReVerify"
          >重新识别</van-button
        >
        <van-button
          @click="handleBack"
          :color="globalStyle.themeColor"
          class="rBtn"
          type="primary"
          >返回首页</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Notify, NavBar } from "vant";
import globalStyle from "@/utils/style";
export default {
  components: {
    vanField: Field,
    vanNavBar: NavBar,
  },
  data() {
    return {
      globalStyle: globalStyle,
      submitLoading: false,
      searchForm: {
        ticketNum: "",
      },
      type: 1,
      msg: "",
    };
  },

  created() {
    this.msg = this.$route.query.msg || "未知，请重试";
    this.type = this.$route.query.type || 1;
  },

  methods: {
    // 点击返回
    onClickLeft() {
      this.$router.go(-1);
    },

    // 返回首页
    handleBack() {
      this.$router.push({ path: "/" });
    },

    handleReVerify() {
      if (this.type == 1) {
        this.$router.push({
          path: "/",
        });
      } else if (this.type == 2) {
        this.$router.push({
          path: "/ticketNum",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../css.scss";
.main ::v-deep .van-nav-bar .van-icon {
  color: $--font-color;
}
.main {
  width: 375px;
  height: 100%;
  overflow: scroll;
  background-color: $--bg-color;
  border: 0.01px solid rgba(0, 0, 0, 0);
  .resultBox {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(calc(-50% - 23px));
    .smile {
      width: 80px;
      height: 80px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .describ {
      width: 100%;
      text-align: center;
      font-size: 14px;
      color: $--gray-color;
      margin-top: 50px;
    }
    .fail {
      font-size: 18px;
      color: #f50f0a;
      text-align: center;
      margin-top: 20px;
    }
    .btnBox {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      padding: 0 15px;
      margin-top: 60px;
      .leftBtn {
        flex: 2;
      }
      .rBtn {
        flex: 3;
        margin-left: 10px;
      }
    }
  }
}
</style>
